<template>
  <div class="container pb-4">
    <div id="headerImg" class="">
     <b-img class="mb-2" src="https://www.scanandbook.co.uk/footfest/img/panoramic_view_01-1-1.jpg"  fluid alt="Responsive image"></b-img>
    </div>
    <h4>Farthingstone Foot Fest</h4>
    <div id="statusJumbotron" v-if="eventStatus !== 'Open'  && $store.state.loggedIn == false" class="mt-4">
      <eventStatusJumbotron :eventStatusText="eventStatusText" :eventStatusSubtext="eventStatusSubtext" />
    </div>
    <div id="entryForm" v-else>
      <h5 class="mb-3"  v-if="!confirmed">Entry Form</h5>
      <b-overlay :show="showOverlay" rounded="sm">
        <div id="confirmed" v-if="confirmed" class="p-2 border">

            <div id="confirmed-summary" class="flex-container">
                <h5>{{ confirmationText }}</h5>
                <p>Thank you very much for registering for the Farthingstone Foot Fest {{ $store.state.eventYear }}. You will shortly receive a confirmatory email providing you with all the relevant information, including your number for the day .</p><p> We look forward to seeing you on the day</p>
                <p><strong>Please note that the email will come from our booking system provider (noreply@scanandbook.co.uk)</strong></p>
              <div class="flex-item text-left">
                  <h6 class="mt-4">Your registration details</h6>
                  <div class="pl-2">
                    <span class="customText"><strong>Name: </strong> {{form.firstName + " " + form.lastName }}</span>
                    <span class="customText"><strong> Email :</strong> {{ form.email || "Not provided" }}</span>
                    <span class="customText"><strong> Event : </strong> {{ eventOptions.filter( event=> event.value == form.eventSelected)[0].text }}</span>           
                  </div>
                </div>
            </div>
            <div class="flex-container">
              <div class="flex-item text-left pl-3 pt-2">
                <strong>Payment Details: </strong>
                <div v-if='form.paymentType === "C"' class="pt-3 pl-2">
                  <span class="customTitle">A cheque payable to <strong>Farthingstone Foot Fest</strong> for the amount of <strong>{{eventFee}}</strong> sent to:</span>
                  <span v-for="(addressLine, index) in $store.state.paymentAddress" :key="'addressLine' +index" class="customText">{{addressLine}}</span>
                  <span class="customTextImportant">Please record <strong>FFF</strong> followed by <strong>your name and number (from the confirmation of registration email that you will receive)</strong> on the back of the cheque as the reference.</span>
                  <span class="customTextImportant">If no email address is being provided with this registration, please include a stamped addressed envelope.</span>
              
                </div>
                <div v-if='form.paymentType === "B"' class="pt-3 pl-3">
                  <span class="customTitle">Payment made by Bank Transfer of <strong>{{eventFee}}</strong> to: </span>
                  <span class="customText"><strong>Bank Account Name: </strong>Farthingstone Foot Fest</span>
                  <span class="customText"><strong> Sort Code:</strong> 30 98 97</span>
                  <span class="customText"><strong>Account Number:</strong> 72792660</span>
                  <span class="customTextImportant">Please record <strong>FFF</strong> followed by <strong>your name and number (from the confirmation of registration email that you will receive)</strong> as the reference.</span>
                </div>
            </div>
            <h6 v-if="$store.state.loggedIn" class="text-danger"><a href="dashboard">Return to admin dashboard</a></h6>
          </div>                
        </div>
        <div id="entry-form" v-if="!confirmed">
          <h6 v-if="$store.state.loggedIn" class="text-danger"><span v-if="eventStatus === 'Closed'">Event registration is currently closed - </span>You are logged as in admin user and are able to add registrations</h6>
          <h6 v-if="$store.state.loggedIn" class="text-danger"><a href="dashboard">Return to admin dashboard</a></h6>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-0 variant="info" class="next-link">Event Details</b-button>
            </b-card-header>
            <b-collapse id="accordion-0" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-button v-b-toggle.accordion-1 variant="link" class="next-link">next</b-button>
                <div class="pt-4">
                <event-details />
                </div>
                <b-button v-b-toggle.accordion-1 variant="link" class="next-link">next</b-button>
              </b-card-body>
            </b-collapse>
          </b-card> 
          
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="info">Your Details</b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="pb-2">
                <b-button v-b-toggle.accordion-0 variant="link" class="back-link">back</b-button>
                <b-button v-b-toggle.accordion-2 variant="link" class="next-link">next</b-button>
                </div>
              <div class="flex-container mt-4 py-2">
              <div class="flex-item">
                  <b-input-group prepend="First Name:" class="my-3">
                    <b-form-input v-model="form.firstName" :state="firstNameState"></b-form-input>
                  </b-input-group>             
              </div>
                <div class="flex-item">
                  <b-input-group prepend="Last Name:" class="my-3">
                    <b-form-input v-model="form.lastName" :state="lastNameState"></b-form-input>
                  </b-input-group>             
              </div>
              </div>
              <div class="flex-container">
              <div class="flex-item">
                  <b-input-group prepend="Mobile Number:" class="my-3">
                    <b-form-input type="tel" v-model="form.mobileNumber"></b-form-input>
                  </b-input-group>             
              </div>
                <div class="flex-item">
                  <b-input-group prepend="Email:" class="my-3">
                    <b-form-input   v-model="form.email"></b-form-input>
                  </b-input-group>             
              </div>
              </div>
              <div class="flex-container">
              <div class="flex-item">
                  <b-input-group prepend="Car Registration:" class="my-3">
                    <b-form-input  v-model="form.carReg"></b-form-input>
                  </b-input-group>             
              </div>
                <div class="flex-item py-4">
                  <p>If under 18 please contact Chris FitzGerald 07761 180369 to discuss your application.</p>
  <!--                     <b-form-group label="On the date of the event I will be" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="form.over18Selected"
                        :options="over18Options"
                        :aria-describedby="ariaDescribedby"
                        name="over18"
                        stacked
                      ></b-form-radio-group>
                    </b-form-group>  --> 
              </div>
              </div>
              <div id="addressContainer">
              <address-component :addressData="form.address" />
              </div>
              <b-button v-b-toggle.accordion-0 variant="link" class="back-link">back</b-button>
              <b-button v-b-toggle.accordion-2 variant="link" class="next-link">next</b-button>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 variant="info">Event Options</b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="flex-container">
                  <div class="flex-item">
                    <h6>26 Miles, 15 miles, 13 miles or 11 Miles Routes</h6>
                  </div>
                </div>
                <div class="flex-container"> 
                    <div class="flex-item">
                      <b-input-group prepend="I will be:" class="my-3">
                        <b-form-radio-group
                          v-model="form.subEventSelected"
                          :options="subEventOptions"
                          @change="form.eventSelected = null"
                          :aria-describedby="ariaDescribedby"
                          button-variant="outline-primary"
                          buttons
                        ></b-form-radio-group>
                      </b-input-group>
                    </div>
                      <div class="flex-item">
                      <b-input-group prepend="Select a distance" class="my-3" v-if="form.subEventSelected != null">
                          <b-form-select v-model="form.eventSelected" 
                          :options="eventOptions.filter(option => option.category == form.subEventSelected)"
                          :state="eventState">
                          </b-form-select>
                      </b-input-group>
                    </div>
                </div>
                  <b-button v-b-toggle.accordion-1 variant="link" class="back-link">back</b-button>
                  <b-button v-b-toggle.accordion-3 variant="link" class="next-link">next</b-button>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 variant="info">Additonal Information 	&amp; Payment</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="flex-container">
                  <div class="flex-item"> 
                      <b-input-group prepend="Emergency Number" class="my-3">
                        <b-form-input type="tel"  v-model="form.emergencyNumber" :state="emergencyNumberState"></b-form-input>
                      </b-input-group>                
                  </div>
                    <div class="flex-item">
                      <b-input-group prepend="Person to Contact" class="my-3">
                        <b-form-input  v-model="form.emergencyContact" :state="emergencyContactState"></b-form-input>
                      </b-input-group>         
                  </div>
                    <div class="flex-item">
                      <b-input-group prepend="Retlationship to Contact" class="my-3">
                        <b-form-input  v-model="form.emergencyRelationship" :state="emergencyContactRelationshipState"></b-form-input>
                      </b-input-group>         
                  </div>
                </div>
                <div class="flex-container mt-3">
                    <div class="flex-item">
                      <b-input-group prepend="Meal options" class="my-3">
                          <b-form-select v-model="form.strMealChoice" 
                          :options="mealOptions"
                          :state="mealOptionState"
                          >
                          </b-form-select>
                      </b-input-group>
                    </div>
                  <div class="flex-item">
                        <b-form-group
                          label="Dietary Requirements"
                          label-for="dietryTextarea"
                          description="Maximum 750 characters "
                          class="mb-0"
                        >
                        <b-form-textarea
                          id="dietryTextarea"
                          v-model="form.strDietary"
                          placeholder=""
                          maxlength="750"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                        </b-form-group>
                  </div>
                </div>
                <div class="flex-container mt-3">
                  <div class="flex-item">
                        <b-form-group
                          label="Comments"
                          label-for="commentsTextarea"
                          description="Maximum 750 characters "
                          class="mb-0"
                        >
                      <b-form-textarea
                        id="commentsTextarea"
                        v-model="form.strComments"
                        placeholder=""
                        maxlength="750"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                        </b-form-group>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item mt-4">
                    <label for="paymentType" v-if="form.eventSelected == null" class="text-danger">No event has been selected</label>    
                      <b-input-group prepend="Pay by:" class="mb-3">
                        <b-form-radio-group
                          v-model="form.paymentType"
                          :options="paymentOptions"
                          :aria-describedby="ariaDescribedby"
                          button-variant="outline-primary"
                          buttons
                          :disabled='eventFee === "No event selected"'
                          :state="emergencyContactState"
                          id="paymentTyp"
                        ></b-form-radio-group>
                      </b-input-group>  
                        
                  </div>
                    <div class="flex-item">
                        <div v-if='form.paymentType === "C"' class="pl-4 pt-2">
                          <span class="customTitle">A cheque payable to <strong>Farthingstone Foot Fest</strong> for the amount of <strong>{{eventFee}}</strong> sent to:</span>
                          <span v-for="(addressLine, index) in $store.state.paymentAddress" :key="'addressLine' +index" class="customText">{{addressLine}}</span>
                          <span class="customTextImportant">Please record <strong>FFF</strong> followed by <strong>your name and number (from the confirmation of registration email that you will receive)</strong> on the back of the cheque as the reference.</span>
                          <span class="customTextImportant">If no email address is being provided with this registration, please include a stamped addressed envelope.</span>
                      
                        </div>
                        <div v-if='form.paymentType === "B"' class="pl-4 pt-2">
                          <span class="customTitle">Payment made by Bank Transfer of <strong>{{eventFee}}</strong> to: </span>
                          <span class="customText"><strong>Bank Account Name: </strong>Farthingstone Foot Fest</span>
                          <span class="customText"><strong> Sort Code:</strong> 30 98 97</span>
                          <span class="customText"><strong>Account Number:</strong> 72792660</span>
                          <span class="customTextImportant">Please record <strong>FFF</strong> followed by <strong>your name and number (from the confirmation of registration email that you will receive)</strong> as the reference.</span>
                        </div>
                    </div>
                </div>
                <b-button v-b-toggle.accordion-2 variant="link" class="back-link">back</b-button>
                <b-button v-b-toggle.accordion-4 variant="link" class="next-link">next</b-button>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-4 variant="info">Conditions of taking part in the Farthingstone Foot Fest</b-button>
            </b-card-header>
            <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div style="clear: both: display: block" class="mb-4">
                  <b-button v-b-toggle.accordion-3 variant="link" class="back-link">back</b-button>
                  <b-button v-b-toggle.accordion-5 variant="link" class="next-link">next</b-button>
                </div>
                <div class="pt-4">   
                      <terms-conditions />
                  <div class="">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="termsAccepted"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted">
                      <strong>  I have read the conditions and agree to abide by the rules. I am in good health and have no medical condition that may cause either undue concern or inconvenience to others.</strong>
                      </b-form-checkbox>
                    </div>  
                  </div>
                  <b-button v-b-toggle.accordion-3 variant="link" class="back-link">back</b-button>
                  <b-button v-b-toggle.accordion-5 variant="link" class="next-link">next</b-button>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-5 variant="info">Summary and Confirmation</b-button>
            </b-card-header>
            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                  <div class="flex-container">
                    <div class="flex-item pb-4">
                          <span class="customText"><strong>Your Details: </strong><span :class="participantState">{{participantStateText}}</span></span>
                          <span class="customText"><strong>Event Options: </strong><span :class="eventOptionsState">{{eventOptionsStateText}}</span></span>
                          <span class="customText"><strong>Add. Info. &amp; Payment: </strong><span :class="additionalInfoState">{{additionalInfoStateText}}</span></span>
                          <span class="customText"><strong>Terms &amp; Condtions: </strong><span :class="TermsConditionsState">{{TermsConditionsStateText}}</span></span>
                  
                    </div>             
                  </div>
                  <div class="flex-container mt-4">
                    <div class="flex-item">
                          <b-button block variant="success" @click.once="submitForm()" :disabled="submitDisabled">Submit</b-button>
                    </div>
                  </div>
                  <b-button v-b-toggle.accordion-4 variant="link" class="back-link">back</b-button>
              </b-card-body>
            </b-collapse>
          </b-card>


        </div>

          <template #overlay>
            <div class="text-center">
                <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
                <p id="cancel-label">{{ overlayText }}...</p>
            </div>
          </template>          
      </b-overlay>
      <div id="footer">Booking System By <a href="https://www.creativeconnections.co.uk/" target="_blank"> Creative Connections</a> &copy; {{currentYear}} </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import EventDetails from './EventDetails.vue'
import TermsConditions from './TermsConditions.vue'
import AddressComponent from './AddressComponent.vue'
import EventStatusJumbotron from './EventStatusJumbotron.vue'
import {RepositoryFactory} from '../api/RepositoryFactory'
const RepoPost = RepositoryFactory.post('registration')
const settingsGet = RepositoryFactory.get('settings')
import {CommonMixin} from '@/mixins/CommonMixin'
export default {
  name: 'EntryForm',
  components: {AddressComponent, EventDetails, TermsConditions, EventStatusJumbotron},
  mixins: [CommonMixin],
  data(){
      return {
        eventStatusText: "",
        eventStatusSubtext: "",
        eventStatus: "Open",
        currentYear: moment().year(),
        confirmationText: "Thank you your registration is confirmed",
        overlayText: "Submitting Registration",
        confirmed: false,
        showOverlay: false,

            termsAccepted: 'not_accepted'
      }
  },
  methods: {
    async saveRegistration()
    {
        await RepoPost.postRegister(this.form)
    },
    async getEventDetails()
    {
        await settingsGet.getEventDetails().then(response=>{
          console.log(response.data)
          const eventData = response.data
          console.log(eventData)
          this.eventStatus = eventData.filter(item => item.name == 'eventStatus')[0].value
          this.eventStatusText = eventData.filter(item => item.name == 'eventStatusText')[0].value
          this.eventStatusSubtext = eventData.filter(item => item.name == 'eventStatusSubtext')[0].value
        })
    },
    showOverlayFn(){
        this.showOverlay = true
      setTimeout(()=>{  
          this.showOverlay = false
          this.confirmed = true
          this.overlayText = "Saving changes"
      }, 3000);        
    },
    submitForm(){
      this.showOverlayFn()
      this.saveRegistration()
     //console.log(this.form)
    }
  },
  mounted(){
    this.getEventDetails()
  },
  computed: {
      participantState(){        
        return (this.firstNameState && this.lastNameState  && this.form.address.addressLine1.length >= 1  && this.form.address.postcode.length >= 1) ? "complete" : "incomplete"
      },
      participantStateText(){        
        return (this.firstNameState && this.lastNameState  && this.form.address.addressLine1.length >= 1  && this.form.address.postcode.length >= 1)? "Complete" : "Incomplete"
      },
      firstNameState() {
        return this.form.firstName.length >= 1
      },
      lastNameState() {
        return this.form.lastName.length >= 1
      },
      eventState()
      {
         return  this.form.eventSelected != null
      },
      eventOptionsState()
      {
         return (this.eventState)? "complete" : "incomplete"
      },
      eventOptionsStateText()
      {
         return (this.eventState)? "Complete" : "Incomplete"
      },
      additionalInfoState()
      {
         return (this.emergencyContactState && this.emergencyNumberState && this.form.paymentType != null  && this.form.strMealChoice != 'select' && this.form.emergencyRelationship.length >= 2)? "complete" : "incomplete"
      },
      mealOptionState()
      {
        return this.form.strMealChoice != 'select'
      },
      emergencyContactRelationshipState()
      {
        return this.form.emergencyRelationship.length >= 2
      },
      additionalInfoStateText()
      {
         return (this.emergencyContactState && this.emergencyNumberState && this.form.paymentType != null  && this.form.strMealChoice != 'select')? "Complete" : "Incomplete"
      },

      TermsConditionsState()
      {
         return (this.termsAccepted === "accepted")? "complete" : "incomplete"
      },
      TermsConditionsStateText()
      {
         return (this.termsAccepted === "accepted")? "Accepted" : "Incomplete"
      },
      emergencyContactState()
      {
        return this.form.emergencyContact.length >= 2
      },      
      emergencyNumberState()
      {
        return this.form.emergencyNumber.length >= 11
      },
      dobState() {
        return this.form.dob != null
      },
      submitDisabled()
      {
        return (this.eventOptionsState === 'complete' && this.participantState  === 'complete' && this.additionalInfoState  === 'complete' && this.termsAccepted === "accepted" )? false : true
      },
      eventFee(){
        let fee = 0.00
        switch(this.form.eventSelected)
        {
          case "W26":
          case  "R26":      
          fee = "£" + this.$store.state.EntryFee26Miles;
          break;
          case "W15":
          case "R15":
          case "W11":
          case "R11":
          fee = "£" + this.$store.state.EntryFee15Miles;
          break;
          default:
          fee = "No event selected"

        }
        return fee
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


  .flex-item .btn
  {
    font-size: 0.9em
  }

.next-link {
float: right
}

.back-link {
float: left
}

#footer{
  padding-top: 30px;
  font-size: 0.7em;
  font-weight: bold;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.incomplete, .complete
{
  font-weight: bold;
  float: right;
  padding-right: 20px
}

.incomplete{
  color: red
}

.complete{
  color: green
}
.flex-container
{
  flex-direction: row
}
@media (min-width: 768px) { 

  #addressContainer{
  width: 50%;
  }

  .container{
    width: 75%;
  }

  .flex-item
  {
    width: 50%
  }

}
.card-body{ 
  text-align: left
}

.customTitle
{
  margin-bottom: 10px
}

.customTextImportant, .customText, .customTitle
{
  display: block;
}

.customText{
  padding-left: 10px
}

.customTextImportant
{
  color: red;
  font-style: italic;
  margin-top: 10px;
}
</style>
