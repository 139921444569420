<template>
  <div class="container pt-2">
    <h5 class="text-center mb-3">{{ $store.state.eventDisplayDate}}</h5>
    <h6>THE COURSE</h6>
    <p>{{ $store.state.paragraph1Text}}</p>
    <p>If the full course is too challenging entrants may complete either one of the loops:</p>
    <ul>
      <li>The first loop is 15 miles but Checkpoint 3 is at the 13 mile point for those wishing to limit their distance to a half marathon (pick-up is available but may have to wait until the vehicle is free).</li>
      <li>The second loop is 11 miles. Start times are later to allow for checkpoint set-ups.</li>
    </ul>
    
    <p>{{ $store.state.paragraph2Text}}</p>
    <h6>TIMES</h6>
   <dl class="row">
      <dt class="col-sm-3">26 Miles:</dt>
      <dd class="col-sm-9">start: walkers 08.00 onwards, runners 09.00 onwards.</dd>
			<dt class="col-sm-3"></dt>
			<dd class="col-sm-9">	last entries: walkers 09.00, runners 11.00. 
      </dd>
      <dt class="col-sm-3">13 & 15 miles:</dt>
      <dd class="col-sm-9">start: walkers & runners 09.15 onwards; </dd>
			<dt class="col-sm-3"></dt>
      <dd class="col-sm-9">	last entries: walkers 10.30, runners 11.00. 
      </dd>
      <dt class="col-sm-3">11 Miles:</dt>
      <dd class="col-sm-9">start: walkers & runners 11.30 onwards; </dd>
			<dt class="col-sm-3"></dt>
			<dd class="col-sm-9">	last entries: walkers 1 pm, runners 1.30 pm.
      </dd>
   </dl>
    <h6>ENTRY FEE:</h6>
    <dl class="row">
      <dt class="col-sm-3">26 Miles: </dt>
      <dd class="col-sm-9">{{ $store.state.EntryFee26MilesText }}</dd>
      <dt class="col-sm-3">15, 13 or 11 miles:</dt>
      <dd class="col-sm-9">{{ $store.state.EntryFee15MilesText }}</dd>
    </dl>

    <strong>Your entry fee includes</strong>
    <ol>
      <li>{{$store.state.entryInlcudesText1}}</li>
      <li>{{$store.state.entryInlcudesText2}}</li>
      <li>{{$store.state.entryInlcudesText3}}</li>
      <li>{{$store.state.entryInlcudesText4}}</li>
      <li>{{$store.state.entryInlcudesText5}}</li>
      <li>{{$store.state.entryInlcudesText6}}</li>
    </ol>
    <div class="">
    <p class="text-danger" v-if="$store.state.alertText.length >= 1"><strong>{{$store.state.alertText}}</strong></p>
    <h6>Registration:</h6>
    <p>The event is organised by <strong>Farthingstone Foot Fest Committee</strong> in accordance with the guidelines of the LDWA. {{$store.state.startAndFinishText }}.</p>
    <p>Advance registering is via our dedicated booking website only.  To access it, please go to <a href="http://www.farthingstone.org.uk/index.html" target="_blank">www.farthingstone.org.uk</a> and then click on the ‘Farthingstone Foot Fest’ page where you will find up to date information plus a click button which takes you direct to the booking website. This website will be closed at 6pm on Thursday 30 May. </p>
    <p>On completion of the entry details, you will be sent a confirmation email including your entry number, how to pay and links to a map of the route.</p>
    <p>Any final instructions will be sent together with comprehensive updated route descriptions and a map showing the start location and parking one week in advance of the event.</p>
    <p>We have introduced a limit of 150 entrants this time.  If planning to register on the day, please check the website to make sure there will be space.</p>
    <p><strong>Proceeds:</strong></p>
    <p>All proceeds go towards supporting Farthingstone village charities and activities plus this year we will donate 10% of our surplus to Macmillan Cancer Support. </p>
    <div class="text-center mb-4">
      <p><strong>Full details and updates at:</strong></p>
      <h2><a href="http://www.farthingstone.org.uk/index.html" target="_blank" style="color: red;">www.farthingstone.org.uk</a></h2>
    </div>
    <p><strong>Sponsorship:</strong></p>
    <p><strong>If any entrants are planning to seek sponsorship, the Committee is suggesting that this year’s charity should be Macmillan Cancer Support.</strong></p> 
     <div class="text-center">
      <img class="mb-2" src="../img/macmillan_cancer.png" />
    </div>
    <p><strong>Please consider passing funds to this worthwhile cause. Sponsorship forms available from their website at </strong><a href="http://www.macmillan.org.uk" target="_blank">www.macmillan.org.uk</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventDetails',
  props: {
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h6{
  font-weight: bold;
}
</style>
