<template>
  <div class="container">
    <h5>FFF 2024' CONDITIONS OF ENTRY: </h5>
    <ol>
      <li>Participants will usually be 18 or over. Any applicant who will be under 18 at the time of the event will need the agreement of their parent or legal guardian and will also need to be accompanied by a named adult - please call Chris Fitzgerald on 07761 180369  to discuss their entry further.</li>
      <li>Entry is limited to 150. Please check on the website <a href='http://www.farthingstone.org.uk' target="_blank">www.farthingstone.org.uk</a>  (and click on 'Farthingstone Foot Fest') to ensure there is still space before booking</li>
      <li>Entrants’ details will be held in an electronic retrieval system to be used only for the purposes of administering the event.  The data will not be passed on to any third party. By registering all participants accept this situation.</li>
      <li>The checkpoints will close through the day with the latest time at 18.00. The organisers reserve the right to request the retirement of any participant, who, in their opinion, is unlikely to reach the next checkpoint by the required time. Any participant retiring at a checkpoint will be transported to the finish.</li>
      <li>Any participant retiring must inform a walk official at a checkpoint or call in to one of the control numbers listed on the instructions.</li>
      <li>The walks are entirely within Explorer OL Map 207 (1:25000) or in Landranger 152 (1:50000). We recommend all walkers should carry a copy of one of these but a full route description will be available plus an excerpt of the detailed map.</li>
      <li>The organisers recommend participants should also carry a compass, suitable clothing (in event of bad weather), food/drink for consumption during walk, mobile phone, whistle and basic first aid for minor injuries.</li>
      <li>Depending on Covid-type guidelines at the time, participants should bring their own face masks and hand sanitizer.</li>
      <li>To limit plastic waste, participants should bring their own mug for use throughout the day.</li>
      <li>All must agree to abide by the Country Code. The routes are on footpaths throughout (except short sections of roadway). Taking shortcuts over farmland or private property is forbidden. Please take care at the occasional road crossings.</li>
      <li>Car Parking: Farthingstone is only a small village and on-street parking can cause problems especially with very large farm vehicles going through the village.  A local farmer has kindly supplied car parking which is fully sign posted. This car park will close at 7pm so please take note if finishing late or visiting the pub afterwards. We ask for your car number in the event of problems with your vehicle. </li>
      <li>We love dogs but ask that you don’t bring one with you for this event (except if a recognised assistance dog). There are likely to be cattle in a number of the fields and our insurance does not cover dogs so you would need your own pet owners liability insurance.</li>
      <li>The organisers reserve the right to impose additional rules on the day, especially in the event of bad weather or changes in Covid-type regulations. </li>
      <li>The organisers can accept no responsibility for injury, damage or other loss however sustained.</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
  props: {
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
