<template>
      <b-jumbotron :header="eventStatusText" :lead="eventStatusSubtext"  bg-variant="info" text-variant="white" border-variant="dark">
      </b-jumbotron>
</template>
<script>
export default{
    name : 'EventStatusJumbotron',
    props: ["eventStatusText", "eventStatusSubtext"]
}
</script>
<style scoped>
.display-3{
    font-size: 2.5rem
}
</style>